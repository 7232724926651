@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-layout {
  min-height: 100vh;
  min-height: 100dvh;
  width: 100%;

  display: grid;
  grid-template-rows: auto 1fr auto;

  background: linear-gradient(45deg, #eee4ff, #ffffff, #edf6ff, #fff1f8);
  background-size: 300% 300%;

  animation: color 6s ease-in-out infinite;
}

@keyframes color {
  0% {
    background-position: 0 50%;
  }
  25% {
    background-position: 25% 75%;
  }
  50% {
    background-position: 100% 50%;
  }
  25% {
    background-position: 25% 75%;
  }
  100% {
    background-position: 0 50%;
  }
}

.trans {
  background: linear-gradient(to left, white 50%, #ec6627 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
}

.trans:hover {
  background-position: left;
}

.transa {
  background: linear-gradient(to left, white 50%, #258bff 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
}

.transa:hover {
  background-position: left;
}

.transb {
  background: linear-gradient(to left, white 50%, #22D3EE 50%) right;
  background-size: 200%;
  transition: .5s ease-out;
}

.transb:hover {
  background-position: left;
}

.sw {
  width: calc(100%);
  height: calc(100%);
}